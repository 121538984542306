<template>
  <el-form>
    <div class="item-box xa-cell">
      <div class="label">示例图</div>
      <div>
        <FormUpload v-model="form.logo" max="1" mode="edit" />
        <p class="xa-txt-placeholder xa-mgt-8">推荐尺寸:180*180px</p>
      </div>
    </div>
    <div class="item-box xa-cell">
      <div class="label">分类名称</div>
      <div>
        <el-input v-model="form.name"></el-input>
        <p class="xa-txt-placeholder xa-mgt-8">分类名称请简短些</p>
      </div>
    </div>
    <el-form-item class="btn-wrap xa-cell">
      <el-button type="primary" @click="submitForm('ruleForm')" size="small">
        <span>提交</span>
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { editCategoryOrder } from '@target/apis/mall'
export default {
  components: {
    FormUpload: () => import('@/components/xa-form/coms/FormUpload.vue')
  },
  props: {
    oldData: {
      type: Object
    }
  },
  data() {
    return {
      form: {
        guid: '',
        up_guid: '',
        logo: [],
        name: ''
      }
    }
  },
  methods: {
    async submitForm() {
      let msg
      if (this.form.logo === 0) {
        msg = '请上传图片'
      } else if (!this.form.name) {
        msg = '请输入分类名称'
      }
      if (msg) {
        this.$message.error(msg)
      } else {
        const loading = this.$loading()
        try {
          let data = await this.$actionWithConfirm(
            editCategoryOrder({
              name: this.form.name,
              logo: this.form.logo[0],
              guid: this.form.guid,
              up_guid: this.form.up_guid
            })
          )
          this.$emit('submit', data)
        } catch (error) {
          throw error
        } finally {
          loading.close()
        }
      }
    }
  },
  created() {
    if (this.oldData) {
      if (this.oldData.logo) {
        this.form.logo = [this.oldData.logo]
        this.form.name = this.oldData.name
      }
      this.form.guid = this.oldData.guid
      this.form.up_guid = this.oldData.upGuid
    }
    console.log('this.form', this.form)
  }
}
</script>
<style lang="scss" scoped>
/deep/ div.xa-upload {
  padding: 0;
  .tips-wrap {
    display: none;
  }
  .xa-upload__wrap {
    border: none;
    padding: 0;
    &:hover {
      border: none;
    }
  }
}
.item-box {
  margin-bottom: 16px;
  align-items: baseline;
}
.label {
  line-height: 40px;
  min-width: 6em;
  &::before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
  }
}
.btn-wrap {
  justify-content: flex-end;
}
</style>
